import React from "react"

import "./Header.css"

function Header() {
  return (
    <div className="header">
      <h1>
        <span>Covid-19</span> Tracking
      </h1>
    </div>
  )
}

export default Header
