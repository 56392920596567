const data = {
  Global: {
    NewConfirmed: 100282,
    TotalConfirmed: 1162857,
    NewDeaths: 5658,
    TotalDeaths: 63263,
    NewRecovered: 15405,
    TotalRecovered: 230845,
  },
  Countries: [
    {
      Country: "ALA Aland Islands",
      CountryCode: "AX",
      Slug: "ala-aland-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Afghanistan",
      CountryCode: "AF",
      Slug: "afghanistan",
      NewConfirmed: 18,
      TotalConfirmed: 299,
      NewDeaths: 1,
      TotalDeaths: 7,
      NewRecovered: 0,
      TotalRecovered: 10,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Albania",
      CountryCode: "AL",
      Slug: "albania",
      NewConfirmed: 29,
      TotalConfirmed: 333,
      NewDeaths: 3,
      TotalDeaths: 20,
      NewRecovered: 10,
      TotalRecovered: 99,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Algeria",
      CountryCode: "DZ",
      Slug: "algeria",
      NewConfirmed: 80,
      TotalConfirmed: 1251,
      NewDeaths: 25,
      TotalDeaths: 130,
      NewRecovered: 28,
      TotalRecovered: 90,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "American Samoa",
      CountryCode: "AS",
      Slug: "american-samoa",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Andorra",
      CountryCode: "AD",
      Slug: "andorra",
      NewConfirmed: 27,
      TotalConfirmed: 466,
      NewDeaths: 1,
      TotalDeaths: 17,
      NewRecovered: 5,
      TotalRecovered: 21,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Angola",
      CountryCode: "AO",
      Slug: "angola",
      NewConfirmed: 2,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 1,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Anguilla",
      CountryCode: "AI",
      Slug: "anguilla",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Antarctica",
      CountryCode: "AQ",
      Slug: "antarctica",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Antigua and Barbuda",
      CountryCode: "AG",
      Slug: "antigua-and-barbuda",
      NewConfirmed: 0,
      TotalConfirmed: 15,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Argentina",
      CountryCode: "AR",
      Slug: "argentina",
      NewConfirmed: 186,
      TotalConfirmed: 1451,
      NewDeaths: 4,
      TotalDeaths: 43,
      NewRecovered: 13,
      TotalRecovered: 279,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Armenia",
      CountryCode: "AM",
      Slug: "armenia",
      NewConfirmed: 34,
      TotalConfirmed: 770,
      NewDeaths: 0,
      TotalDeaths: 7,
      NewRecovered: 0,
      TotalRecovered: 43,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Aruba",
      CountryCode: "AW",
      Slug: "aruba",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Australia",
      CountryCode: "AU",
      Slug: "australia",
      NewConfirmed: 220,
      TotalConfirmed: 5550,
      NewDeaths: 2,
      TotalDeaths: 30,
      NewRecovered: 52,
      TotalRecovered: 701,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Austria",
      CountryCode: "AT",
      Slug: "austria",
      NewConfirmed: 257,
      TotalConfirmed: 11781,
      NewDeaths: 18,
      TotalDeaths: 186,
      NewRecovered: 485,
      TotalRecovered: 2507,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Azerbaijan",
      CountryCode: "AZ",
      Slug: "azerbaijan",
      NewConfirmed: 78,
      TotalConfirmed: 521,
      NewDeaths: 0,
      TotalDeaths: 5,
      NewRecovered: 0,
      TotalRecovered: 32,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bahrain",
      CountryCode: "BH",
      Slug: "bahrain",
      NewConfirmed: 16,
      TotalConfirmed: 688,
      NewDeaths: 0,
      TotalDeaths: 4,
      NewRecovered: 41,
      TotalRecovered: 423,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bangladesh",
      CountryCode: "BD",
      Slug: "bangladesh",
      NewConfirmed: 9,
      TotalConfirmed: 70,
      NewDeaths: 2,
      TotalDeaths: 8,
      NewRecovered: 4,
      TotalRecovered: 30,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Barbados",
      CountryCode: "BB",
      Slug: "barbados",
      NewConfirmed: 1,
      TotalConfirmed: 52,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Belarus",
      CountryCode: "BY",
      Slug: "belarus",
      NewConfirmed: 89,
      TotalConfirmed: 440,
      NewDeaths: 1,
      TotalDeaths: 5,
      NewRecovered: 0,
      TotalRecovered: 53,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Belgium",
      CountryCode: "BE",
      Slug: "belgium",
      NewConfirmed: 1661,
      TotalConfirmed: 18431,
      NewDeaths: 140,
      TotalDeaths: 1283,
      NewRecovered: 375,
      TotalRecovered: 3247,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Belize",
      CountryCode: "BZ",
      Slug: "belize",
      NewConfirmed: 0,
      TotalConfirmed: 4,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Benin",
      CountryCode: "BJ",
      Slug: "benin",
      NewConfirmed: 0,
      TotalConfirmed: 16,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bermuda",
      CountryCode: "BM",
      Slug: "bermuda",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bhutan",
      CountryCode: "BT",
      Slug: "bhutan",
      NewConfirmed: 0,
      TotalConfirmed: 5,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bolivia",
      CountryCode: "BO",
      Slug: "bolivia",
      NewConfirmed: 7,
      TotalConfirmed: 139,
      NewDeaths: 1,
      TotalDeaths: 10,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bosnia and Herzegovina",
      CountryCode: "BA",
      Slug: "bosnia-and-herzegovina",
      NewConfirmed: 45,
      TotalConfirmed: 624,
      NewDeaths: 4,
      TotalDeaths: 21,
      NewRecovered: 3,
      TotalRecovered: 30,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Botswana",
      CountryCode: "BW",
      Slug: "botswana",
      NewConfirmed: 0,
      TotalConfirmed: 4,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bouvet Island",
      CountryCode: "BV",
      Slug: "bouvet-island",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Brazil",
      CountryCode: "BR",
      Slug: "brazil",
      NewConfirmed: 1304,
      TotalConfirmed: 10360,
      NewDeaths: 86,
      TotalDeaths: 445,
      NewRecovered: 0,
      TotalRecovered: 127,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "British Indian Ocean Territory",
      CountryCode: "IO",
      Slug: "british-indian-ocean-territory",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "British Virgin Islands",
      CountryCode: "VG",
      Slug: "british-virgin-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Brunei Darussalam",
      CountryCode: "BN",
      Slug: "brunei",
      NewConfirmed: 1,
      TotalConfirmed: 135,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 1,
      TotalRecovered: 66,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Bulgaria",
      CountryCode: "BG",
      Slug: "bulgaria",
      NewConfirmed: 18,
      TotalConfirmed: 503,
      NewDeaths: 3,
      TotalDeaths: 17,
      NewRecovered: 4,
      TotalRecovered: 34,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Burkina Faso",
      CountryCode: "BF",
      Slug: "burkina-faso",
      NewConfirmed: 16,
      TotalConfirmed: 318,
      NewDeaths: 0,
      TotalDeaths: 16,
      NewRecovered: 16,
      TotalRecovered: 66,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Burundi",
      CountryCode: "BI",
      Slug: "burundi",
      NewConfirmed: 0,
      TotalConfirmed: 3,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cambodia",
      CountryCode: "KH",
      Slug: "cambodia",
      NewConfirmed: 0,
      TotalConfirmed: 114,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 15,
      TotalRecovered: 50,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cameroon",
      CountryCode: "CM",
      Slug: "cameroon",
      NewConfirmed: 46,
      TotalConfirmed: 555,
      NewDeaths: 1,
      TotalDeaths: 9,
      NewRecovered: 0,
      TotalRecovered: 17,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Canada",
      CountryCode: "CA",
      Slug: "canada",
      NewConfirmed: 541,
      TotalConfirmed: 12978,
      NewDeaths: 39,
      TotalDeaths: 217,
      NewRecovered: 402,
      TotalRecovered: 2577,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cape Verde",
      CountryCode: "CV",
      Slug: "cape-verde",
      NewConfirmed: 1,
      TotalConfirmed: 7,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cayman Islands",
      CountryCode: "KY",
      Slug: "cayman-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Central African Republic",
      CountryCode: "CF",
      Slug: "central-african-republic",
      NewConfirmed: 0,
      TotalConfirmed: 8,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Chad",
      CountryCode: "TD",
      Slug: "chad",
      NewConfirmed: 1,
      TotalConfirmed: 9,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Chile",
      CountryCode: "CL",
      Slug: "chile",
      NewConfirmed: 424,
      TotalConfirmed: 4161,
      NewDeaths: 5,
      TotalDeaths: 27,
      NewRecovered: 101,
      TotalRecovered: 528,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "China",
      CountryCode: "CN",
      Slug: "china",
      NewConfirmed: 32,
      TotalConfirmed: 82543,
      NewDeaths: 4,
      TotalDeaths: 3330,
      NewRecovered: 186,
      TotalRecovered: 76946,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Christmas Island",
      CountryCode: "CX",
      Slug: "christmas-island",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cocos (Keeling) Islands",
      CountryCode: "CC",
      Slug: "cocos-keeling-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Colombia",
      CountryCode: "CO",
      Slug: "colombia",
      NewConfirmed: 139,
      TotalConfirmed: 1406,
      NewDeaths: 7,
      TotalDeaths: 32,
      NewRecovered: 30,
      TotalRecovered: 85,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Comoros",
      CountryCode: "KM",
      Slug: "comoros",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Congo (Brazzaville)",
      CountryCode: "CG",
      Slug: "congo-brazzaville",
      NewConfirmed: 0,
      TotalConfirmed: 22,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Congo (Kinshasa)",
      CountryCode: "CD",
      Slug: "congo-kinshasa",
      NewConfirmed: 20,
      TotalConfirmed: 154,
      NewDeaths: 5,
      TotalDeaths: 18,
      NewRecovered: 0,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cook Islands",
      CountryCode: "CK",
      Slug: "cook-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Costa Rica",
      CountryCode: "CR",
      Slug: "costa-rica",
      NewConfirmed: 19,
      TotalConfirmed: 435,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 2,
      TotalRecovered: 13,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Croatia",
      CountryCode: "HR",
      Slug: "croatia",
      NewConfirmed: 47,
      TotalConfirmed: 1126,
      NewDeaths: 4,
      TotalDeaths: 12,
      NewRecovered: 27,
      TotalRecovered: 119,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cuba",
      CountryCode: "CU",
      Slug: "cuba",
      NewConfirmed: 19,
      TotalConfirmed: 288,
      NewDeaths: 0,
      TotalDeaths: 6,
      NewRecovered: 0,
      TotalRecovered: 15,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Cyprus",
      CountryCode: "CY",
      Slug: "cyprus",
      NewConfirmed: 30,
      TotalConfirmed: 426,
      NewDeaths: 0,
      TotalDeaths: 11,
      NewRecovered: 5,
      TotalRecovered: 33,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Czech Republic",
      CountryCode: "CZ",
      Slug: "czech-republic",
      NewConfirmed: 381,
      TotalConfirmed: 4472,
      NewDeaths: 6,
      TotalDeaths: 59,
      NewRecovered: 6,
      TotalRecovered: 78,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Côte d'Ivoire",
      CountryCode: "CI",
      Slug: "cote-divoire",
      NewConfirmed: 27,
      TotalConfirmed: 245,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 6,
      TotalRecovered: 25,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Denmark",
      CountryCode: "DK",
      Slug: "denmark",
      NewConfirmed: 323,
      TotalConfirmed: 4269,
      NewDeaths: 22,
      TotalDeaths: 161,
      NewRecovered: 92,
      TotalRecovered: 1379,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Djibouti",
      CountryCode: "DJ",
      Slug: "djibouti",
      NewConfirmed: 1,
      TotalConfirmed: 50,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 8,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Dominica",
      CountryCode: "DM",
      Slug: "dominica",
      NewConfirmed: 2,
      TotalConfirmed: 14,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Dominican Republic",
      CountryCode: "DO",
      Slug: "dominican-republic",
      NewConfirmed: 0,
      TotalConfirmed: 1488,
      NewDeaths: 0,
      TotalDeaths: 68,
      NewRecovered: 0,
      TotalRecovered: 16,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Ecuador",
      CountryCode: "EC",
      Slug: "ecuador",
      NewConfirmed: 97,
      TotalConfirmed: 3465,
      NewDeaths: 27,
      TotalDeaths: 172,
      NewRecovered: 35,
      TotalRecovered: 100,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Egypt",
      CountryCode: "EG",
      Slug: "egypt",
      NewConfirmed: 85,
      TotalConfirmed: 1070,
      NewDeaths: 5,
      TotalDeaths: 71,
      NewRecovered: 25,
      TotalRecovered: 241,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "El Salvador",
      CountryCode: "SV",
      Slug: "el-salvador",
      NewConfirmed: 10,
      TotalConfirmed: 56,
      NewDeaths: 1,
      TotalDeaths: 3,
      NewRecovered: 2,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Equatorial Guinea",
      CountryCode: "GQ",
      Slug: "equatorial-guinea",
      NewConfirmed: 0,
      TotalConfirmed: 16,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Eritrea",
      CountryCode: "ER",
      Slug: "eritrea",
      NewConfirmed: 7,
      TotalConfirmed: 29,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Estonia",
      CountryCode: "EE",
      Slug: "estonia",
      NewConfirmed: 78,
      TotalConfirmed: 1039,
      NewDeaths: 1,
      TotalDeaths: 13,
      NewRecovered: 11,
      TotalRecovered: 59,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Ethiopia",
      CountryCode: "ET",
      Slug: "ethiopia",
      NewConfirmed: 3,
      TotalConfirmed: 38,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 1,
      TotalRecovered: 4,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Falkland Islands (Malvinas)",
      CountryCode: "FK",
      Slug: "falkland-islands-malvinas",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Faroe Islands",
      CountryCode: "FO",
      Slug: "faroe-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Fiji",
      CountryCode: "FJ",
      Slug: "fiji",
      NewConfirmed: 5,
      TotalConfirmed: 12,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Finland",
      CountryCode: "FI",
      Slug: "finland",
      NewConfirmed: 267,
      TotalConfirmed: 1882,
      NewDeaths: 5,
      TotalDeaths: 25,
      NewRecovered: 0,
      TotalRecovered: 300,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "France",
      CountryCode: "FR",
      Slug: "france",
      NewConfirmed: 25646,
      TotalConfirmed: 90848,
      NewDeaths: 1054,
      TotalDeaths: 7574,
      NewRecovered: 1437,
      TotalRecovered: 15572,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "French Guiana",
      CountryCode: "GF",
      Slug: "french-guiana",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "French Polynesia",
      CountryCode: "PF",
      Slug: "french-polynesia",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "French Southern Territories",
      CountryCode: "TF",
      Slug: "french-southern-territories",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Gabon",
      CountryCode: "GA",
      Slug: "gabon",
      NewConfirmed: 0,
      TotalConfirmed: 21,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Gambia",
      CountryCode: "GM",
      Slug: "gambia",
      NewConfirmed: 0,
      TotalConfirmed: 4,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Georgia",
      CountryCode: "GE",
      Slug: "georgia",
      NewConfirmed: 7,
      TotalConfirmed: 162,
      NewDeaths: 1,
      TotalDeaths: 1,
      NewRecovered: 8,
      TotalRecovered: 36,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Germany",
      CountryCode: "DE",
      Slug: "germany",
      NewConfirmed: 4933,
      TotalConfirmed: 96092,
      NewDeaths: 169,
      TotalDeaths: 1444,
      NewRecovered: 1825,
      TotalRecovered: 26400,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Ghana",
      CountryCode: "GH",
      Slug: "ghana",
      NewConfirmed: 0,
      TotalConfirmed: 205,
      NewDeaths: 0,
      TotalDeaths: 5,
      NewRecovered: 0,
      TotalRecovered: 31,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Gibraltar",
      CountryCode: "GI",
      Slug: "gibraltar",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Greece",
      CountryCode: "GR",
      Slug: "greece",
      NewConfirmed: 60,
      TotalConfirmed: 1673,
      NewDeaths: 5,
      TotalDeaths: 68,
      NewRecovered: 0,
      TotalRecovered: 78,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Greenland",
      CountryCode: "GL",
      Slug: "greenland",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Grenada",
      CountryCode: "GD",
      Slug: "grenada",
      NewConfirmed: 0,
      TotalConfirmed: 12,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guadeloupe",
      CountryCode: "GP",
      Slug: "guadeloupe",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guam",
      CountryCode: "GU",
      Slug: "guam",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guatemala",
      CountryCode: "GT",
      Slug: "guatemala",
      NewConfirmed: 11,
      TotalConfirmed: 61,
      NewDeaths: 1,
      TotalDeaths: 2,
      NewRecovered: 3,
      TotalRecovered: 15,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guernsey",
      CountryCode: "GG",
      Slug: "guernsey",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guinea",
      CountryCode: "GN",
      Slug: "guinea",
      NewConfirmed: 38,
      TotalConfirmed: 111,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 3,
      TotalRecovered: 5,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guinea-Bissau",
      CountryCode: "GW",
      Slug: "guinea-bissau",
      NewConfirmed: 3,
      TotalConfirmed: 18,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Guyana",
      CountryCode: "GY",
      Slug: "guyana",
      NewConfirmed: 0,
      TotalConfirmed: 23,
      NewDeaths: 0,
      TotalDeaths: 4,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Haiti",
      CountryCode: "HT",
      Slug: "haiti",
      NewConfirmed: 2,
      TotalConfirmed: 20,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Heard and Mcdonald Islands",
      CountryCode: "HM",
      Slug: "heard-and-mcdonald-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Holy See (Vatican City State)",
      CountryCode: "VA",
      Slug: "holy-see-vatican-city-state",
      NewConfirmed: 0,
      TotalConfirmed: 7,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Honduras",
      CountryCode: "HN",
      Slug: "honduras",
      NewConfirmed: 42,
      TotalConfirmed: 264,
      NewDeaths: 0,
      TotalDeaths: 15,
      NewRecovered: 0,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Hong Kong, SAR China",
      CountryCode: "HK",
      Slug: "hong-kong-sar-china",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Hungary",
      CountryCode: "HU",
      Slug: "hungary",
      NewConfirmed: 55,
      TotalConfirmed: 678,
      NewDeaths: 6,
      TotalDeaths: 32,
      NewRecovered: 15,
      TotalRecovered: 58,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Iceland",
      CountryCode: "IS",
      Slug: "iceland",
      NewConfirmed: 53,
      TotalConfirmed: 1417,
      NewDeaths: 0,
      TotalDeaths: 4,
      NewRecovered: 87,
      TotalRecovered: 396,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "India",
      CountryCode: "IN",
      Slug: "india",
      NewConfirmed: 515,
      TotalConfirmed: 3082,
      NewDeaths: 14,
      TotalDeaths: 86,
      NewRecovered: 37,
      TotalRecovered: 229,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Indonesia",
      CountryCode: "ID",
      Slug: "indonesia",
      NewConfirmed: 106,
      TotalConfirmed: 2092,
      NewDeaths: 10,
      TotalDeaths: 191,
      NewRecovered: 16,
      TotalRecovered: 150,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Iran, Islamic Republic of",
      CountryCode: "IR",
      Slug: "iran",
      NewConfirmed: 2560,
      TotalConfirmed: 55743,
      NewDeaths: 158,
      TotalDeaths: 3452,
      NewRecovered: 1801,
      TotalRecovered: 19736,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Iraq",
      CountryCode: "IQ",
      Slug: "iraq",
      NewConfirmed: 58,
      TotalConfirmed: 878,
      NewDeaths: 2,
      TotalDeaths: 56,
      NewRecovered: 33,
      TotalRecovered: 259,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Ireland",
      CountryCode: "IE",
      Slug: "ireland",
      NewConfirmed: 331,
      TotalConfirmed: 4604,
      NewDeaths: 17,
      TotalDeaths: 137,
      NewRecovered: 20,
      TotalRecovered: 25,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Isle of Man",
      CountryCode: "IM",
      Slug: "isle-of-man",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Israel",
      CountryCode: "IL",
      Slug: "israel",
      NewConfirmed: 423,
      TotalConfirmed: 7851,
      NewDeaths: 4,
      TotalDeaths: 44,
      NewRecovered: 24,
      TotalRecovered: 427,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Italy",
      CountryCode: "IT",
      Slug: "italy",
      NewConfirmed: 4805,
      TotalConfirmed: 124632,
      NewDeaths: 681,
      TotalDeaths: 15362,
      NewRecovered: 1238,
      TotalRecovered: 20996,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Jamaica",
      CountryCode: "JM",
      Slug: "jamaica",
      NewConfirmed: 6,
      TotalConfirmed: 53,
      NewDeaths: 0,
      TotalDeaths: 3,
      NewRecovered: 5,
      TotalRecovered: 7,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Japan",
      CountryCode: "JP",
      Slug: "japan",
      NewConfirmed: 522,
      TotalConfirmed: 3139,
      NewDeaths: 14,
      TotalDeaths: 77,
      NewRecovered: 0,
      TotalRecovered: 514,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Jersey",
      CountryCode: "JE",
      Slug: "jersey",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Jordan",
      CountryCode: "JO",
      Slug: "jordan",
      NewConfirmed: 13,
      TotalConfirmed: 323,
      NewDeaths: 0,
      TotalDeaths: 5,
      NewRecovered: 16,
      TotalRecovered: 74,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Kenya",
      CountryCode: "KE",
      Slug: "kenya",
      NewConfirmed: 4,
      TotalConfirmed: 126,
      NewDeaths: 0,
      TotalDeaths: 4,
      NewRecovered: 0,
      TotalRecovered: 4,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Kiribati",
      CountryCode: "KI",
      Slug: "kiribati",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Korea (North)",
      CountryCode: "KP",
      Slug: "korea-north",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Korea (South)",
      CountryCode: "KR",
      Slug: "korea-south",
      NewConfirmed: 94,
      TotalConfirmed: 10156,
      NewDeaths: 3,
      TotalDeaths: 177,
      NewRecovered: 304,
      TotalRecovered: 6325,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Kuwait",
      CountryCode: "KW",
      Slug: "kuwait",
      NewConfirmed: 62,
      TotalConfirmed: 479,
      NewDeaths: 1,
      TotalDeaths: 1,
      NewRecovered: 11,
      TotalRecovered: 93,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Kyrgyzstan",
      CountryCode: "KG",
      Slug: "kyrgyzstan",
      NewConfirmed: 14,
      TotalConfirmed: 144,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 3,
      TotalRecovered: 9,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Lao PDR",
      CountryCode: "LA",
      Slug: "lao-pdr",
      NewConfirmed: 0,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Latvia",
      CountryCode: "LV",
      Slug: "latvia",
      NewConfirmed: 16,
      TotalConfirmed: 509,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Lebanon",
      CountryCode: "LB",
      Slug: "lebanon",
      NewConfirmed: 12,
      TotalConfirmed: 520,
      NewDeaths: 0,
      TotalDeaths: 17,
      NewRecovered: 4,
      TotalRecovered: 54,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Lesotho",
      CountryCode: "LS",
      Slug: "lesotho",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Liberia",
      CountryCode: "LR",
      Slug: "liberia",
      NewConfirmed: 3,
      TotalConfirmed: 10,
      NewDeaths: 1,
      TotalDeaths: 1,
      NewRecovered: 3,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Libya",
      CountryCode: "LY",
      Slug: "libya",
      NewConfirmed: 7,
      TotalConfirmed: 18,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Liechtenstein",
      CountryCode: "LI",
      Slug: "liechtenstein",
      NewConfirmed: 2,
      TotalConfirmed: 77,
      NewDeaths: 1,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Lithuania",
      CountryCode: "LT",
      Slug: "lithuania",
      NewConfirmed: 75,
      TotalConfirmed: 771,
      NewDeaths: 2,
      TotalDeaths: 11,
      NewRecovered: 0,
      TotalRecovered: 7,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Luxembourg",
      CountryCode: "LU",
      Slug: "luxembourg",
      NewConfirmed: 117,
      TotalConfirmed: 2729,
      NewDeaths: 0,
      TotalDeaths: 31,
      NewRecovered: 0,
      TotalRecovered: 500,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Macao, SAR China",
      CountryCode: "MO",
      Slug: "macao-sar-china",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Macedonia, Republic of",
      CountryCode: "MK",
      Slug: "macedonia",
      NewConfirmed: 53,
      TotalConfirmed: 483,
      NewDeaths: 5,
      TotalDeaths: 17,
      NewRecovered: 0,
      TotalRecovered: 20,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Madagascar",
      CountryCode: "MG",
      Slug: "madagascar",
      NewConfirmed: 0,
      TotalConfirmed: 70,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Malawi",
      CountryCode: "MW",
      Slug: "malawi",
      NewConfirmed: 1,
      TotalConfirmed: 4,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Malaysia",
      CountryCode: "MY",
      Slug: "malaysia",
      NewConfirmed: 150,
      TotalConfirmed: 3483,
      NewDeaths: 4,
      TotalDeaths: 57,
      NewRecovered: 88,
      TotalRecovered: 915,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Maldives",
      CountryCode: "MV",
      Slug: "maldives",
      NewConfirmed: 0,
      TotalConfirmed: 19,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 13,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mali",
      CountryCode: "ML",
      Slug: "mali",
      NewConfirmed: 2,
      TotalConfirmed: 41,
      NewDeaths: 0,
      TotalDeaths: 3,
      NewRecovered: 1,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Malta",
      CountryCode: "MT",
      Slug: "malta",
      NewConfirmed: 11,
      TotalConfirmed: 213,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Marshall Islands",
      CountryCode: "MH",
      Slug: "marshall-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Martinique",
      CountryCode: "MQ",
      Slug: "martinique",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mauritania",
      CountryCode: "MR",
      Slug: "mauritania",
      NewConfirmed: 0,
      TotalConfirmed: 6,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mauritius",
      CountryCode: "MU",
      Slug: "mauritius",
      NewConfirmed: 10,
      TotalConfirmed: 196,
      NewDeaths: 0,
      TotalDeaths: 7,
      NewRecovered: 7,
      TotalRecovered: 7,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mayotte",
      CountryCode: "YT",
      Slug: "mayotte",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mexico",
      CountryCode: "MX",
      Slug: "mexico",
      NewConfirmed: 178,
      TotalConfirmed: 1688,
      NewDeaths: 10,
      TotalDeaths: 60,
      NewRecovered: 0,
      TotalRecovered: 633,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Micronesia, Federated States of",
      CountryCode: "FM",
      Slug: "micronesia",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Moldova",
      CountryCode: "MD",
      Slug: "moldova",
      NewConfirmed: 161,
      TotalConfirmed: 752,
      NewDeaths: 4,
      TotalDeaths: 12,
      NewRecovered: 3,
      TotalRecovered: 29,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Monaco",
      CountryCode: "MC",
      Slug: "monaco",
      NewConfirmed: 2,
      TotalConfirmed: 66,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mongolia",
      CountryCode: "MN",
      Slug: "mongolia",
      NewConfirmed: 0,
      TotalConfirmed: 14,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Montenegro",
      CountryCode: "ME",
      Slug: "montenegro",
      NewConfirmed: 27,
      TotalConfirmed: 201,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Montserrat",
      CountryCode: "MS",
      Slug: "montserrat",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Morocco",
      CountryCode: "MA",
      Slug: "morocco",
      NewConfirmed: 128,
      TotalConfirmed: 919,
      NewDeaths: 11,
      TotalDeaths: 59,
      NewRecovered: 9,
      TotalRecovered: 66,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Mozambique",
      CountryCode: "MZ",
      Slug: "mozambique",
      NewConfirmed: 0,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 1,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Myanmar",
      CountryCode: "MM",
      Slug: "myanmar",
      NewConfirmed: 1,
      TotalConfirmed: 21,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Namibia",
      CountryCode: "NA",
      Slug: "namibia",
      NewConfirmed: 0,
      TotalConfirmed: 14,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Nauru",
      CountryCode: "NR",
      Slug: "nauru",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Nepal",
      CountryCode: "NP",
      Slug: "nepal",
      NewConfirmed: 3,
      TotalConfirmed: 9,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Netherlands",
      CountryCode: "NL",
      Slug: "netherlands",
      NewConfirmed: 906,
      TotalConfirmed: 16727,
      NewDeaths: 166,
      TotalDeaths: 1656,
      NewRecovered: 2,
      TotalRecovered: 262,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Netherlands Antilles",
      CountryCode: "AN",
      Slug: "netherlands-antilles",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "New Caledonia",
      CountryCode: "NC",
      Slug: "new-caledonia",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "New Zealand",
      CountryCode: "NZ",
      Slug: "new-zealand",
      NewConfirmed: 82,
      TotalConfirmed: 950,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 24,
      TotalRecovered: 127,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Nicaragua",
      CountryCode: "NI",
      Slug: "nicaragua",
      NewConfirmed: 0,
      TotalConfirmed: 5,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Niger",
      CountryCode: "NE",
      Slug: "niger",
      NewConfirmed: 24,
      TotalConfirmed: 144,
      NewDeaths: 3,
      TotalDeaths: 8,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Nigeria",
      CountryCode: "NG",
      Slug: "nigeria",
      NewConfirmed: 4,
      TotalConfirmed: 214,
      NewDeaths: 0,
      TotalDeaths: 4,
      NewRecovered: 0,
      TotalRecovered: 25,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Niue",
      CountryCode: "NU",
      Slug: "niue",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Norfolk Island",
      CountryCode: "NF",
      Slug: "norfolk-island",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Northern Mariana Islands",
      CountryCode: "MP",
      Slug: "northern-mariana-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Norway",
      CountryCode: "NO",
      Slug: "norway",
      NewConfirmed: 180,
      TotalConfirmed: 5550,
      NewDeaths: 3,
      TotalDeaths: 62,
      NewRecovered: 0,
      TotalRecovered: 32,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Oman",
      CountryCode: "OM",
      Slug: "oman",
      NewConfirmed: 25,
      TotalConfirmed: 277,
      NewDeaths: 1,
      TotalDeaths: 2,
      NewRecovered: 4,
      TotalRecovered: 61,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Pakistan",
      CountryCode: "PK",
      Slug: "pakistan",
      NewConfirmed: 132,
      TotalConfirmed: 2818,
      NewDeaths: 1,
      TotalDeaths: 41,
      NewRecovered: 5,
      TotalRecovered: 131,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Palau",
      CountryCode: "PW",
      Slug: "palau",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Palestinian Territory",
      CountryCode: "PS",
      Slug: "palestine",
      NewConfirmed: 23,
      TotalConfirmed: 217,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 21,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Panama",
      CountryCode: "PA",
      Slug: "panama",
      NewConfirmed: 198,
      TotalConfirmed: 1673,
      NewDeaths: 4,
      TotalDeaths: 41,
      NewRecovered: 3,
      TotalRecovered: 13,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Papua New Guinea",
      CountryCode: "PG",
      Slug: "papua-new-guinea",
      NewConfirmed: 0,
      TotalConfirmed: 1,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Paraguay",
      CountryCode: "PY",
      Slug: "paraguay",
      NewConfirmed: 4,
      TotalConfirmed: 96,
      NewDeaths: 0,
      TotalDeaths: 3,
      NewRecovered: 6,
      TotalRecovered: 12,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Peru",
      CountryCode: "PE",
      Slug: "peru",
      NewConfirmed: 151,
      TotalConfirmed: 1746,
      NewDeaths: 12,
      TotalDeaths: 73,
      NewRecovered: 377,
      TotalRecovered: 914,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Philippines",
      CountryCode: "PH",
      Slug: "philippines",
      NewConfirmed: 76,
      TotalConfirmed: 3094,
      NewDeaths: 8,
      TotalDeaths: 144,
      NewRecovered: 5,
      TotalRecovered: 57,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Pitcairn",
      CountryCode: "PN",
      Slug: "pitcairn",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Poland",
      CountryCode: "PL",
      Slug: "poland",
      NewConfirmed: 244,
      TotalConfirmed: 3627,
      NewDeaths: 8,
      TotalDeaths: 79,
      NewRecovered: 60,
      TotalRecovered: 116,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Portugal",
      CountryCode: "PT",
      Slug: "portugal",
      NewConfirmed: 638,
      TotalConfirmed: 10524,
      NewDeaths: 20,
      TotalDeaths: 266,
      NewRecovered: 7,
      TotalRecovered: 75,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Puerto Rico",
      CountryCode: "PR",
      Slug: "puerto-rico",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Qatar",
      CountryCode: "QA",
      Slug: "qatar",
      NewConfirmed: 250,
      TotalConfirmed: 1325,
      NewDeaths: 0,
      TotalDeaths: 3,
      NewRecovered: 16,
      TotalRecovered: 109,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Republic of Kosovo",
      CountryCode: "XK",
      Slug: "kosovo",
      NewConfirmed: 9,
      TotalConfirmed: 135,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 6,
      TotalRecovered: 16,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Romania",
      CountryCode: "RO",
      Slug: "romania",
      NewConfirmed: 430,
      TotalConfirmed: 3613,
      NewDeaths: 13,
      TotalDeaths: 146,
      NewRecovered: 46,
      TotalRecovered: 329,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Russian Federation",
      CountryCode: "RU",
      Slug: "russia",
      NewConfirmed: 582,
      TotalConfirmed: 4731,
      NewDeaths: 9,
      TotalDeaths: 43,
      NewRecovered: 52,
      TotalRecovered: 333,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Rwanda",
      CountryCode: "RW",
      Slug: "rwanda",
      NewConfirmed: 13,
      TotalConfirmed: 102,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Réunion",
      CountryCode: "RE",
      Slug: "réunion",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint Helena",
      CountryCode: "SH",
      Slug: "saint-helena",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint Kitts and Nevis",
      CountryCode: "KN",
      Slug: "saint-kitts-and-nevis",
      NewConfirmed: 0,
      TotalConfirmed: 9,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint Lucia",
      CountryCode: "LC",
      Slug: "saint-lucia",
      NewConfirmed: 1,
      TotalConfirmed: 14,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint Pierre and Miquelon",
      CountryCode: "PM",
      Slug: "saint-pierre-and-miquelon",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint Vincent and Grenadines",
      CountryCode: "VC",
      Slug: "saint-vincent-and-the-grenadines",
      NewConfirmed: 4,
      TotalConfirmed: 7,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint-Barthélemy",
      CountryCode: "BL",
      Slug: "saint-barthélemy",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saint-Martin (French part)",
      CountryCode: "MF",
      Slug: "saint-martin-french-part",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Samoa",
      CountryCode: "WS",
      Slug: "samoa",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "San Marino",
      CountryCode: "SM",
      Slug: "san-marino",
      NewConfirmed: 14,
      TotalConfirmed: 259,
      NewDeaths: 2,
      TotalDeaths: 32,
      NewRecovered: 6,
      TotalRecovered: 27,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Sao Tome and Principe",
      CountryCode: "ST",
      Slug: "sao-tome-and-principe",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Saudi Arabia",
      CountryCode: "SA",
      Slug: "saudi-arabia",
      NewConfirmed: 140,
      TotalConfirmed: 2179,
      NewDeaths: 4,
      TotalDeaths: 29,
      NewRecovered: 69,
      TotalRecovered: 420,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Senegal",
      CountryCode: "SN",
      Slug: "senegal",
      NewConfirmed: 12,
      TotalConfirmed: 219,
      NewDeaths: 1,
      TotalDeaths: 2,
      NewRecovered: 6,
      TotalRecovered: 72,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Serbia",
      CountryCode: "RS",
      Slug: "serbia",
      NewConfirmed: 148,
      TotalConfirmed: 1624,
      NewDeaths: 5,
      TotalDeaths: 44,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Seychelles",
      CountryCode: "SC",
      Slug: "seychelles",
      NewConfirmed: 0,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Sierra Leone",
      CountryCode: "SL",
      Slug: "sierra-leone",
      NewConfirmed: 2,
      TotalConfirmed: 4,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Singapore",
      CountryCode: "SG",
      Slug: "singapore",
      NewConfirmed: 75,
      TotalConfirmed: 1189,
      NewDeaths: 1,
      TotalDeaths: 6,
      NewRecovered: 15,
      TotalRecovered: 297,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Slovakia",
      CountryCode: "SK",
      Slug: "slovakia",
      NewConfirmed: 21,
      TotalConfirmed: 471,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 10,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Slovenia",
      CountryCode: "SI",
      Slug: "slovenia",
      NewConfirmed: 43,
      TotalConfirmed: 977,
      NewDeaths: 2,
      TotalDeaths: 22,
      NewRecovered: 9,
      TotalRecovered: 79,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Solomon Islands",
      CountryCode: "SB",
      Slug: "solomon-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Somalia",
      CountryCode: "SO",
      Slug: "somalia",
      NewConfirmed: 0,
      TotalConfirmed: 7,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "South Africa",
      CountryCode: "ZA",
      Slug: "south-africa",
      NewConfirmed: 80,
      TotalConfirmed: 1585,
      NewDeaths: 0,
      TotalDeaths: 9,
      NewRecovered: 0,
      TotalRecovered: 95,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "South Georgia and the South Sandwich Islands",
      CountryCode: "GS",
      Slug: "south-georgia-and-the-south-sandwich-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "South Sudan",
      CountryCode: "SS",
      Slug: "south-sudan",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Spain",
      CountryCode: "ES",
      Slug: "spain",
      NewConfirmed: 6969,
      TotalConfirmed: 126168,
      NewDeaths: 749,
      TotalDeaths: 11947,
      NewRecovered: 3706,
      TotalRecovered: 34219,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Sri Lanka",
      CountryCode: "LK",
      Slug: "sri-lanka",
      NewConfirmed: 7,
      TotalConfirmed: 166,
      NewDeaths: 1,
      TotalDeaths: 5,
      NewRecovered: 3,
      TotalRecovered: 27,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Sudan",
      CountryCode: "SD",
      Slug: "sudan",
      NewConfirmed: 0,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Suriname",
      CountryCode: "SR",
      Slug: "suriname",
      NewConfirmed: 0,
      TotalConfirmed: 10,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Svalbard and Jan Mayen Islands",
      CountryCode: "SJ",
      Slug: "svalbard-and-jan-mayen-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Swaziland",
      CountryCode: "SZ",
      Slug: "swaziland",
      NewConfirmed: 0,
      TotalConfirmed: 9,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Sweden",
      CountryCode: "SE",
      Slug: "sweden",
      NewConfirmed: 312,
      TotalConfirmed: 6443,
      NewDeaths: 15,
      TotalDeaths: 373,
      NewRecovered: 0,
      TotalRecovered: 205,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Switzerland",
      CountryCode: "CH",
      Slug: "switzerland",
      NewConfirmed: 899,
      TotalConfirmed: 20505,
      NewDeaths: 75,
      TotalDeaths: 666,
      NewRecovered: 1569,
      TotalRecovered: 6415,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Syrian Arab Republic (Syria)",
      CountryCode: "SY",
      Slug: "syria",
      NewConfirmed: 0,
      TotalConfirmed: 16,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 2,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Taiwan, Republic of China",
      CountryCode: "TW",
      Slug: "taiwan",
      NewConfirmed: 7,
      TotalConfirmed: 355,
      NewDeaths: 0,
      TotalDeaths: 5,
      NewRecovered: 0,
      TotalRecovered: 50,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tajikistan",
      CountryCode: "TJ",
      Slug: "tajikistan",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tanzania, United Republic of",
      CountryCode: "TZ",
      Slug: "tanzania",
      NewConfirmed: 0,
      TotalConfirmed: 20,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 3,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Thailand",
      CountryCode: "TH",
      Slug: "thailand",
      NewConfirmed: 89,
      TotalConfirmed: 2067,
      NewDeaths: 1,
      TotalDeaths: 20,
      NewRecovered: 62,
      TotalRecovered: 674,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Timor-Leste",
      CountryCode: "TL",
      Slug: "timor-leste",
      NewConfirmed: 0,
      TotalConfirmed: 1,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Togo",
      CountryCode: "TG",
      Slug: "togo",
      NewConfirmed: 1,
      TotalConfirmed: 41,
      NewDeaths: 0,
      TotalDeaths: 3,
      NewRecovered: 0,
      TotalRecovered: 17,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tokelau",
      CountryCode: "TK",
      Slug: "tokelau",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tonga",
      CountryCode: "TO",
      Slug: "tonga",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Trinidad and Tobago",
      CountryCode: "TT",
      Slug: "trinidad-and-tobago",
      NewConfirmed: 5,
      TotalConfirmed: 103,
      NewDeaths: 0,
      TotalDeaths: 6,
      NewRecovered: 0,
      TotalRecovered: 1,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tunisia",
      CountryCode: "TN",
      Slug: "tunisia",
      NewConfirmed: 58,
      TotalConfirmed: 553,
      NewDeaths: 0,
      TotalDeaths: 18,
      NewRecovered: 0,
      TotalRecovered: 5,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Turkey",
      CountryCode: "TR",
      Slug: "turkey",
      NewConfirmed: 3013,
      TotalConfirmed: 23934,
      NewDeaths: 76,
      TotalDeaths: 501,
      NewRecovered: 302,
      TotalRecovered: 786,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Turkmenistan",
      CountryCode: "TM",
      Slug: "turkmenistan",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Turks and Caicos Islands",
      CountryCode: "TC",
      Slug: "turks-and-caicos-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Tuvalu",
      CountryCode: "TV",
      Slug: "tuvalu",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "US Minor Outlying Islands",
      CountryCode: "UM",
      Slug: "us-minor-outlying-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Uganda",
      CountryCode: "UG",
      Slug: "uganda",
      NewConfirmed: 0,
      TotalConfirmed: 48,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Ukraine",
      CountryCode: "UA",
      Slug: "ukraine",
      NewConfirmed: 153,
      TotalConfirmed: 1225,
      NewDeaths: 5,
      TotalDeaths: 32,
      NewRecovered: 3,
      TotalRecovered: 25,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "United Arab Emirates",
      CountryCode: "AE",
      Slug: "united-arab-emirates",
      NewConfirmed: 241,
      TotalConfirmed: 1505,
      NewDeaths: 1,
      TotalDeaths: 10,
      NewRecovered: 17,
      TotalRecovered: 125,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "United Kingdom",
      CountryCode: "GB",
      Slug: "united-kingdom",
      NewConfirmed: 3788,
      TotalConfirmed: 42477,
      NewDeaths: 709,
      TotalDeaths: 4320,
      NewRecovered: 7,
      TotalRecovered: 215,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "United States of America",
      CountryCode: "US",
      Slug: "united-states",
      NewConfirmed: 32129,
      TotalConfirmed: 275582,
      NewDeaths: 1161,
      TotalDeaths: 7087,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Uruguay",
      CountryCode: "UY",
      Slug: "uruguay",
      NewConfirmed: 31,
      TotalConfirmed: 400,
      NewDeaths: 1,
      TotalDeaths: 5,
      NewRecovered: 25,
      TotalRecovered: 93,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Uzbekistan",
      CountryCode: "UZ",
      Slug: "uzbekistan",
      NewConfirmed: 39,
      TotalConfirmed: 266,
      NewDeaths: 0,
      TotalDeaths: 2,
      NewRecovered: 0,
      TotalRecovered: 25,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Vanuatu",
      CountryCode: "VU",
      Slug: "vanuatu",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Venezuela (Bolivarian Republic)",
      CountryCode: "VE",
      Slug: "venezuela",
      NewConfirmed: 2,
      TotalConfirmed: 155,
      NewDeaths: 0,
      TotalDeaths: 7,
      NewRecovered: 0,
      TotalRecovered: 52,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Viet Nam",
      CountryCode: "VN",
      Slug: "vietnam",
      NewConfirmed: 3,
      TotalConfirmed: 240,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 5,
      TotalRecovered: 90,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Virgin Islands, US",
      CountryCode: "VI",
      Slug: "virgin-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Wallis and Futuna Islands",
      CountryCode: "WF",
      Slug: "wallis-and-futuna-islands",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Western Sahara",
      CountryCode: "EH",
      Slug: "western-sahara",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Yemen",
      CountryCode: "YE",
      Slug: "yemen",
      NewConfirmed: 0,
      TotalConfirmed: 0,
      NewDeaths: 0,
      TotalDeaths: 0,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Zambia",
      CountryCode: "ZM",
      Slug: "zambia",
      NewConfirmed: 0,
      TotalConfirmed: 39,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 2,
      Date: "2020-04-05T06:37:00Z",
    },
    {
      Country: "Zimbabwe",
      CountryCode: "ZW",
      Slug: "zimbabwe",
      NewConfirmed: 0,
      TotalConfirmed: 9,
      NewDeaths: 0,
      TotalDeaths: 1,
      NewRecovered: 0,
      TotalRecovered: 0,
      Date: "2020-04-05T06:37:00Z",
    },
  ],
  Date: "2020-04-05T06:37:00Z",
}

export const countries = {}
for (const country of data["Countries"]) {
  countries[country["Slug"]] = country
}

export const summary = data.Global
